import logo from './logo.svg';
import './App.css';

function App() {
  return (
      <div className="App">
        <div className="MuiBox-root css-vooagt">
          <div className="MuiBox-root css-1q7i39h"></div>
          <div className="MuiGrid-root MuiGrid-container MuiGrid-direction-xs-column css-ujljcm-MuiGrid-root">
            <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 css-zf1fm7-MuiGrid-root">
              <a className="MuiButtonBase-root css-10d1a0h-MuiButtonBase-root" tabIndex="0" href="/dashboard"
              ><img
                  src="https://www.tickettomorrow.com/_next/static/media/Logo.585c4ecc.svg"
                  alt="main logo"
                  width="150"
              /></a>
            </div>
            <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 css-6dalm9-MuiGrid-root">
              <div className="MuiGrid-root MuiGrid-container MuiGrid-item MuiGrid-grid-xs-12 css-19115e0-MuiGrid-root">
                <div className="MuiGrid-root MuiGrid-item css-13i4rnv-MuiGrid-root">
                  <div
                      className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation0 MuiCard-root css-vzkx7z-MuiPaper-root-MuiCard-root"
                  >
                    <div className="MuiBox-root css-1m7zxfz">
                      <div className="organizer_login_area">
                        <div className="container">
                          <div className="row row_middle">
                            <div className="col-md-6">
                              <div className="organizer_login_details oflow-hd">
                                <h2>Sign in for Seamless Access!</h2>
                                <p>
                                  Streamline your experience. Sign in now for instant access to manage events, track
                                  sales, and explore endless possibilities!
                                </p>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="organizer_login_wrapper oflow-hd">
                                <div className="user_login_box_wrapper oflow-hd">
                                  <div className="user_login_box_header oflow-hd"><h2>Organizer Login</h2></div>
                                  <div className="user_login_box_content oflow-hd">
                                    <form>
                                      <div className="row">
                                        <div className="col-12">
                                          <div className="user_login_box_content_single oflow-hd">
                                            <label>Email Address</label>
                                            <div
                                                className="MuiFormControl-root MuiFormControl-fullWidth MuiTextField-root css-wb57ya-MuiFormControl-root-MuiTextField-root"
                                            >
                                              <div
                                                  className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl css-9xhi9s-MuiInputBase-root-MuiOutlinedInput-root"
                                              >
                                                <input
                                                    aria-invalid="false"
                                                    id="email-login"
                                                    name="email"
                                                    placeholder="Enter email address"
                                                    type="email"
                                                    className="MuiInputBase-input MuiOutlinedInput-input css-1u40o6b-MuiInputBase-input-MuiOutlinedInput-input"
                                                    value=""
                                                />
                                                <fieldset
                                                    aria-hidden="true"
                                                    className="MuiOutlinedInput-notchedOutline css-6wp6pb-MuiOutlinedInput-notchedOutline"
                                                >
                                                  <legend className="css-ihdtdm">
                                                    {/*<span className="notranslate">&ZeroWidthSpace;</span>*/}
                                                  </legend>
                                                </fieldset>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-12">
                                          <div className="user_login_box_content_single oflow-hd">
                                            <label>Password</label>
                                            <div
                                                className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-adornedEnd css-nbql5b-MuiInputBase-root-MuiOutlinedInput-root"
                                            >
                                              <input
                                                  aria-invalid="false"
                                                  id="password-signup"
                                                  name="password"
                                                  placeholder="******"
                                                  type="password"
                                                  className="MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputAdornedEnd css-1spscxo-MuiInputBase-input-MuiOutlinedInput-input"
                                                  value=""
                                              />
                                              <div
                                                  className="MuiInputAdornment-root MuiInputAdornment-positionEnd css-1laqsz7-MuiInputAdornment-root"
                                              >
                                                <button
                                                    className="MuiButtonBase-root MuiIconButton-root MuiIconButton-edgeEnd MuiIconButton-sizeLarge css-1a13r4v-MuiButtonBase-root-MuiIconButton-root"
                                                    tabIndex="0"
                                                    type="button"
                                                    aria-label="toggle password visibility"
                                                >
                                                <span
                                                    role="img"
                                                    aria-label="eye-invisible"
                                                    className="anticon anticon-eye-invisible"
                                                ><svg
                                                    viewBox="64 64 896 896"
                                                    focusable="false"
                                                    data-icon="eye-invisible"
                                                    width="1em"
                                                    height="1em"
                                                    fill="currentColor"
                                                    aria-hidden="true"
                                                >
                                                    <path
                                                        d="M942.2 486.2Q889.47 375.11 816.7 305l-50.88 50.88C807.31 395.53 843.45 447.4 874.7 512 791.5 684.2 673.4 766 512 766q-72.67 0-133.87-22.38L323 798.75Q408 838 512 838q288.3 0 430.2-300.3a60.29 60.29 0 000-51.5zm-63.57-320.64L836 122.88a8 8 0 00-11.32 0L715.31 232.2Q624.86 186 512 186q-288.3 0-430.2 300.3a60.3 60.3 0 000 51.5q56.69 119.4 136.5 191.41L112.48 835a8 8 0 000 11.31L155.17 889a8 8 0 0011.31 0l712.15-712.12a8 8 0 000-11.32zM149.3 512C232.6 339.8 350.7 258 512 258c54.54 0 104.13 9.36 149.12 28.39l-70.3 70.3a176 176 0 00-238.13 238.13l-83.42 83.42C223.1 637.49 183.3 582.28 149.3 512zm246.7 0a112.11 112.11 0 01146.2-106.69L401.31 546.2A112 112 0 01396 512z"
                                                    ></path>
                                                    <path
                                                        d="M508 624c-3.46 0-6.87-.16-10.25-.47l-52.82 52.82a176.09 176.09 0 00227.42-227.42l-52.82 52.82c.31 3.38.47 6.79.47 10.25a111.94 111.94 0 01-112 112z"
                                                    ></path></svg
                                                ></span>
                                                </button>
                                              </div>
                                              <fieldset
                                                  aria-hidden="true"
                                                  className="MuiOutlinedInput-notchedOutline css-6wp6pb-MuiOutlinedInput-notchedOutline"
                                              >
                                                <legend className="css-ihdtdm">
                                                  {/*<span className="notranslate">&ZeroWidthSpace;</span>*/}
                                                </legend>
                                              </fieldset>
                                            </div>
                                            <p
                                                className="MuiFormHelperText-root css-1ikhpz-MuiFormHelperText-root"
                                                id="outlined-weight-helper-text"
                                            ></p>
                                          </div>
                                        </div>
                                        <div className="col-12">
                                          <div className="user_login_box_content_single_checkbox oflow-hd">
                                            <input id="rememberme" name="rememberme" type="checkbox"/><label
                                              htmlFor="rememberme"
                                          >Remember me
                                            <svg
                                                width="10"
                                                height="10"
                                                viewBox="0 0 10 10"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                  d="M3.82918 8.72855C3.73373 8.82458 3.60348 8.87814 3.4682 8.87814C3.33293 8.87814 3.20268 8.82458 3.10723 8.72855L0.224383 5.84523C-0.0747942 5.54605 -0.0747942 5.06091 0.224383 4.76231L0.585358 4.40124C0.88463 4.10206 1.3692 4.10206 1.66838 4.40124L3.46823 6.20115L8.33157 1.33766C8.63085 1.03849 9.1159 1.03849 9.41459 1.33766L9.77557 1.69873C10.0747 1.99791 10.0747 2.48296 9.77557 2.78166L3.82918 8.72855Z"
                                                  fill="white"
                                              ></path>
                                            </svg
                                            >
                                          </label>
                                          </div>
                                        </div>
                                        <div className="col-12">
                                          <div className="user_login_box_content_single_btn oflow-hd">
                                            <button type="submit" name="login">Login</button>
                                          </div>
                                        </div>
                                      </div>
                                    </form>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*<div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 css-75h1b-MuiGrid-root">*/}
            {/*  <div className="MuiContainer-root MuiContainer-maxWidthXl css-oigl0b-MuiContainer-root">*/}
            {/*    <div className="MuiStack-root css-50dt4l-MuiStack-root">*/}
            {/*    <span className="MuiTypography-root MuiTypography-subtitle2 css-703uff-MuiTypography-root"*/}
            {/*    >This site is protected by*/}
            {/*      <a*/}
            {/*          className="MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineHover MuiTypography-root MuiTypography-subtitle2 css-nvr8dn-MuiTypography-root-MuiLink-root-MuiTypography-root"*/}
            {/*          href="#mantis-privacy"*/}
            {/*          target="_blank"*/}
            {/*      >Privacy Policy</a*/}
            {/*      ></span*/}
            {/*    >*/}
            {/*      <div className="MuiStack-root css-zswu4t-MuiStack-root">*/}
            {/*        <a*/}
            {/*            className="MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineHover MuiTypography-root MuiTypography-subtitle2 css-m7lnxm-MuiTypography-root-MuiLink-root-MuiTypography-root"*/}
            {/*            href="https://codedthemes.com"*/}
            {/*            target="_blank"*/}
            {/*        >Terms and Conditions</a*/}
            {/*        ><a*/}
            {/*          className="MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineHover MuiTypography-root MuiTypography-subtitle2 css-m7lnxm-MuiTypography-root-MuiLink-root-MuiTypography-root"*/}
            {/*          href="https://codedthemes.com"*/}
            {/*          target="_blank"*/}
            {/*      >Privacy Policy</a*/}
            {/*      >*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
  );
}

export default App;
